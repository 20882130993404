import { PanelName } from '@sendoutcards/editor/dist/types/editorChrome'
import { MinimalPanelFragment } from 'src/graphql/generated/graphql'

export const mapPanelImages = (
  panels: MinimalPanelFragment[],
  isThreePanel: boolean,
  isHorizontal?: boolean,
) => {
  // TODO: What are the names of the flap panels
  return {
    Front: panels[0].previewUrl,
    InsideLeft: panels.find(panel => {
      return isHorizontal
        ? panel.name === 'Inside Top' && !panel.isFullBleed
        : panel.name === 'Inside Left' && !panel.isFullBleed
    })?.previewUrl,
    InsideRight: isThreePanel
      ? panels.find(panel => panel.name === 'Inside Middle')?.previewUrl
      : panels.find(panel =>
          isHorizontal
            ? panel.name === 'Inside Bottom'
            : panel.name === 'Inside Right',
        )?.previewUrl,
    Back: panels.find(panel => panel.name === 'Back')?.previewUrl,
    FlapIn: isThreePanel
      ? panels.find(panel =>
          isHorizontal
            ? panel.name === 'Inside Bottom'
            : panel.name === 'Inside Right',
        )?.previewUrl
      : '',
    FlapOut: isThreePanel
      ? panels.find(panel => panel.name === 'Flap')?.previewUrl
      : '',
  }
}

export const mapPanelControlNames = (panels: MinimalPanelFragment[]) =>
  panels
    .filter(item => item.name !== 'FBBackground')
    .map((fb, i) => {
      return {
        name: fb.name as PanelName, // Ensure the name is typed as PanelName
        index: i,
      }
    })
