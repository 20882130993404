import React from 'react'
import { SVGProps } from './types'

export const PremiumCrown = ({
  size = 16,
  onClick,
  color = 'white',
}: SVGProps) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 8"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75968 5.99131H3.76266H6.29309C6.54926 5.98982 6.70267 5.87812 6.77267 5.63386L6.7727 5.63375C7.02141 4.77145 7.27012 3.90914 7.51437 3.04535C7.55309 2.90833 7.55011 2.76833 7.49501 2.63279C7.36692 2.31556 7.06011 2.1562 6.72352 2.23662C6.40628 2.31258 6.08756 2.39003 5.77181 2.47343C5.68692 2.49726 5.63777 2.47492 5.58564 2.40939C5.17011 1.88811 4.75309 1.36684 4.33458 0.847048C4.25266 0.744282 4.16181 0.651941 4.03522 0.602792C3.74628 0.489601 3.45883 0.574494 3.25181 0.833644C3.11331 1.00653 2.97465 1.1791 2.83604 1.3516C2.54836 1.70965 2.26087 2.06744 1.97543 2.42726C1.93075 2.48386 1.88755 2.49428 1.82202 2.4779C1.52117 2.40045 1.22032 2.32747 0.917979 2.25599C0.900796 2.25187 0.883574 2.24748 0.8663 2.24308C0.777267 2.22039 0.686849 2.19734 0.593298 2.20982C0.164362 2.26492 -0.0888302 2.6462 0.0288295 3.06173C0.268617 3.9062 0.508404 4.75067 0.749681 5.59514C0.833086 5.88556 0.973086 5.99131 1.27989 5.99131H3.75968ZM1.28635 7.55204H3.75869V7.55353H6.2221C6.28912 7.55502 6.35614 7.55055 6.42168 7.53715C6.69572 7.48055 6.84465 7.18268 6.72402 6.93098C6.62721 6.72842 6.44997 6.6748 6.24295 6.6748H1.29678C1.2372 6.6748 1.17614 6.67927 1.11805 6.69119C0.84401 6.7448 0.695074 7.04268 0.815712 7.29587C0.909542 7.49395 1.08231 7.55204 1.28635 7.55204Z"
        fill={color}
      />
    </svg>
  )
}
