import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import useSelector from 'src/hooks/useSelector'
import { Portal } from 'src/portal/portal'

type DialoProps = {
  isOpen: boolean
  onClose: () => void
  title?: string
  maxWidth?: string
  padding?: string
  sheetBgColorOverride?: string
  zIndex?: number
  outerWrapperStyle?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  mobileBreakPointOverride?: number
  /**
   * The point in the Y axis that represents the
   * open state of the mobile from top to bottom
   * e.g: 10vh will set the dialog top close to the
   * top of the screen
   */
  mobileOpenHeight?: string
  shouldHideTitleBar?: boolean
}

export const Dialog: React.FC<DialoProps> = props => {
  const {
    isOpen,
    children,
    onClose,
    title,
    maxWidth = 1000,
    padding,
    sheetBgColorOverride,
    zIndex,
    outerWrapperStyle,
    wrapperStyle,
    mobileOpenHeight,
    shouldHideTitleBar,
    mobileBreakPointOverride = 520,
  } = props

  const isMobile = useSelector(
    state => state.window.width <= mobileBreakPointOverride,
  )

  const wrapperZIndex = zIndex ?? 1000

  const overlayVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }
  const desktopDialogVariants = {
    open: {
      scale: 1,
      opacity: 1,
      x: '-50%',
      y: '-50%',
    },
    closed: {
      scale: 0.1,
      opacity: 0,
      x: '-50%',
      y: '-50%',
    },
  }

  const mobileDialogVariants = {
    open: {
      y: '1%',
    },
    closed: {
      y: '100%',
    },
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <motion.div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              backgroundColor: '#A5A8AB78',
              zIndex: wrapperZIndex,
              backdropFilter: 'blur(4px)',
              WebkitBackdropFilter: 'blur(4px)',
            }}
            variants={overlayVariants}
            initial="closed"
            exit="closed"
            animate={isOpen ? 'open' : 'closed'}
            onClick={onClose}
          />
          <motion.div
            style={{
              position: 'fixed',
              top: isMobile ? (mobileOpenHeight ?? '10vh') : '50%',
              left: isMobile ? '0' : '50%',
              right: isMobile ? '0' : undefined,
              bottom: isMobile ? '0' : undefined,
              transform: isMobile
                ? undefined
                : 'translateX(-50%) translateY(-50%)',
              width: '100dvw',
              maxWidth: maxWidth ?? '100vw',
              maxHeight: `calc(100vh - ${mobileOpenHeight ?? '10vh'})`,
              zIndex: wrapperZIndex + 1,
              borderRadius: '1rem',
              background: sheetBgColorOverride ?? '#F8F5F699',
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              overflow: isMobile ? 'auto' : undefined,
              ...outerWrapperStyle,
            }}
            transition={{
              y: { type: 'spring', stiffness: 300, damping: 30 },
            }}
            variants={isMobile ? mobileDialogVariants : desktopDialogVariants}
            animate={isOpen ? 'open' : 'closed'}
            initial="closed"
            exit="closed"
          >
            {!shouldHideTitleBar && (
              <Flex
                justifyContent="space-between"
                style={{
                  background: sheetBgColorOverride ? 'transparent' : '#fff',
                  borderRadius: '1rem 1rem 0 0',
                  overflow: 'hidden',
                  padding: '0.75rem 2rem',
                  position: isMobile ? 'fixed' : undefined,
                  left: isMobile ? 0 : undefined,
                  right: isMobile ? 0 : undefined,
                }}
              >
                <Flex alignItems="center">
                  {title && (
                    <Text
                      type="largeBody"
                      weight="bold"
                      color="primaryHeading"
                      outset={{ left: 'x1' }}
                    >
                      {title}
                    </Text>
                  )}
                </Flex>
                <Flex
                  borderRadius="small"
                  width="34px"
                  height="34px"
                  style={{
                    backgroundColor: sheetBgColorOverride
                      ? 'transparent'
                      : '#F8F5F6',
                  }}
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={onClose}
                  outset={{ left: 'x1' }}
                >
                  <Icon name="x" size={12} />
                </Flex>
              </Flex>
            )}
            <div
              style={{
                ...wrapperStyle,
                padding:
                  padding ?? `${isMobile ? '60px' : '20px'} 40px 40px 40px`,
              }}
            >
              {children}
            </div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  )
}
