import deepEqual from 'deep-equal'
import { AddressInput } from 'src/graphql/generated/graphql'

const areAddressesEqual = (
  addressOne: AddressInput,
  addressTwo: AddressInput,
) => {
  return deepEqual(addressOne, addressTwo)
}

export default areAddressesEqual
