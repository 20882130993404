import React from 'react'
import { Flex, LoadingSpinner, Text } from '@sendoutcards/quantum-design-ui'
import { Icon as ChromeIcon } from 'src/chrome'
import { Portal } from 'src/portal/portal'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useActions, useSelector, useState } from 'src/hooks'
import { IconType } from 'src/design_system/atoms/icons/types'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { AnimatePresence } from 'framer-motion'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { StepSubscription } from './StepSubscription'
import { StepConfirmation } from './StepConfirmation'
import { PlanFragment } from 'src/graphql/generated/graphql'
import { StepAddOn } from './StepAddOn'

type PackageBundlerProps = {
  packagePlan: PlanFragment
  addon: string
  packageVariantId: string
  premiumSubVariantIds: string[]
  addonVariantIds?: string[]
  onClose: () => void
}

enum Steps {
  Subscription,
  Addon,
  Confirmation,
}

export const PackageBundler: React.FC<PackageBundlerProps> = ({
  packagePlan,
  addon,
  packageVariantId,
  premiumSubVariantIds,
  addonVariantIds,
  onClose,
}) => {
  const isMobile = useSelector(state => state.window.width <= 626)
  const isSmallMobile = useSelector(state => state.window.width <= 400)
  const isTablet = useSelector(state => state.window.width <= 900)
  const actions = useActions()

  const [step, setStep] = useState<Steps>(Steps.Subscription)
  const [selectedPlan, setSelectedPlan] = useState<PlanFragment | undefined>(
    undefined,
  )
  const [selectedAddons, setSelectedAddons] = useState<string[]>([])
  const [selectedPackagePlan, setSelectedPackagePlan] = useState<
    PlanFragment | undefined
  >(packagePlan)
  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(packagePlan.stripeId)

  const isLoading = isCreatingCheckout || isUpdatingCheckout

  const onContinueSubscription = (plan: PlanFragment) => {
    setSelectedPlan(plan)
    setStep(Steps.Addon)
  }
  const onContinueAddon = (addons?: string[]) => {
    setSelectedAddons(addons ?? [])

    setStep(Steps.Confirmation)
  }

  const stepContent: Record<Steps, string> = {
    [Steps.Subscription]:
      'Great choice!  New Subscriber Bonus – Add a monthly subscription to your package now and get your first month free!',
    [Steps.Addon]:
      'Awesome call! Want to get paid to Show Others? Add the optional 90-day Free Gratitude Consultant offer.',
    [Steps.Confirmation]:
      'Review your selected products below, then add them to your cart.',
  }

  const isStepActive = (targetStep: Steps) => step === targetStep

  const goToStep = (newStep: Steps) => setStep(newStep)

  const onBackAddon = () =>
    goToStep(step === Steps.Confirmation ? Steps.Addon : Steps.Subscription)

  const handleSkip = () =>
    goToStep(step === Steps.Subscription ? Steps.Addon : Steps.Confirmation)

  const getContent = () =>
    stepContent[step] ||
    'Way to go! Get started on your gratitude sharing efforts and make a difference today.'

  const addItemsToCart = (
    selectedPlanVariantId?: string,
    shouldCheckout?: boolean,
  ) => {
    const lines = [
      { variantId: packageVariantId, quantity: 1 },
      ...selectedAddons.map(variantId => ({ variantId, quantity: 1 })),
    ]
    if (selectedPlanVariantId) {
      lines.push({ variantId: selectedPlanVariantId, quantity: 1 })
    }
    addToCart(lines)

    actions.checkoutOpenState(shouldCheckout ?? true)
    onClose()
  }

  const onAddToCartAndContinueShopping = (selectedPlanVariantId: string) =>
    addItemsToCart(selectedPlanVariantId, false)

  const onAddToCartAndCheckout = (selectedPlanVariantId?: string) =>
    addItemsToCart(selectedPlanVariantId, true)

  return (
    <Portal>
      {/* --------- BACKDROP --------- */}
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: '#a5a8ab78',
          zIndex: 1000,
          backdropFilter: 'blur(4px)',
        }}
      />
      {/* --------- MODAL --------- */}
      <div
        style={{
          position: 'fixed',
          top: isMobile ? undefined : '20%',
          left: isMobile ? '0' : '50%',
          bottom: isMobile ? 0 : undefined,
          transform: isMobile ? undefined : 'translate(-50%, -20%)',
          minWidth: isMobile ? '100%' : '500px',
          maxWidth: isMobile ? '100%' : isTablet ? '80vw' : '780px',
          maxHeight: isMobile ? '95vh' : '80vh',
          width: '100%',
          overflowY: 'auto',
          zIndex: 1001,
          borderRadius: '1rem',
          backgroundColor: 'rgba(255, 255, 255, 0.84)',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}
      >
        <Flex flexDirection="column">
          {/* --------- HEADER --------- */}
          <Flex
            justifyContent="space-between"
            style={{
              borderRadius: '20px',
              position: 'sticky',
              top: 0,
              padding: isMobile ? '12px 24px' : '20px 32px',
              zIndex: 4,
              backgroundColor: 'transparent',
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
              overflow: 'hidden',
            }}
          >
            <Flex alignItems="center">
              {isStepActive(Steps.Subscription) ? (
                <Icon
                  name={packagePlan.icon.toLowerCase() as IconType}
                  color="#F34293"
                  size={20}
                />
              ) : (
                <ChromeIcon
                  icon="SOCLOGO"
                  color="black"
                  size={20}
                  style={{ display: 'flex' }}
                />
              )}
              <Text
                type="largeBody"
                weight="bold"
                color="primaryHeading"
                outset={{ left: 'x1' }}
              >
                {packagePlan.title}
              </Text>
            </Flex>
            {/* --------- CLOSE BUTTON ---------*/}
            <Flex
              borderRadius="small"
              width="34px"
              height="34px"
              backgroundColor={'#F3F4F6'}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                onClose()
                setSelectedAddons([])
                setSelectedPlan(undefined)
                setSelectedPackagePlan(undefined)
              }}
            >
              <Icon name="x" size={12} />
            </Flex>
          </Flex>
          {/* --------- ERROR NOTIFICATION --------- */}
          {hasCheckoutError && checkoutErrorMessage && (
            <AnimatePresence>
              <ToasterNotification
                backgroundColor={{ swatch: 'danger', shade: '_500' }}
                icon={{
                  size: 'xSmall',
                  name: 'information',
                  primaryColor: 'inverseHeadingText',
                  iconContainerColor: { swatch: 'success', shade: '_400' },
                }}
                label={{
                  color: 'inverseHeading',
                  type: 'footnote',
                  content: checkoutErrorMessage,
                }}
              />
            </AnimatePresence>
          )}
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: isMobile ? '12px 24px' : '20px 32px',
            }}
          >
            {/* --------- PILL PROGRESS INDICATOR --------- */}
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              inset={{ horizontal: 'x4', bottom: 'x2' }}
              style={{
                paddingTop: isMobile ? '8px' : '1rem',
                flex: '1',
              }}
            >
              <Flex columnGap="x1">
                {Object.keys(Steps)
                  .filter(key => isNaN(Number(key)))
                  .map((stepName, index) => {
                    const isCompleted = step > index
                    const isCurrent = step === index
                    return (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        key={stepName}
                        minWidth={
                          isMobile ? '100px' : isTablet ? '120px' : '150px'
                        }
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor:
                            isCurrent && !isCompleted
                              ? '#fff'
                              : isCompleted
                                ? '#D8D8D8'
                                : 'transparent',
                          borderRadius: '50px',
                          padding: isSmallMobile
                            ? '0.5rem .5rem'
                            : isMobile
                              ? '0.6rem 1rem'
                              : '.75rem 1.5rem',
                          zIndex: 3,
                          border:
                            isCurrent && !isCompleted
                              ? 'solid rgba(216, 216, 216, 0.5) 4px'
                              : 'transparent',
                        }}
                      >
                        {isCompleted && !isCurrent && (
                          <Icon name="v_check" color="#fff" size={15} />
                        )}
                        {!isCompleted && !isCurrent && (
                          <Text
                            type={isMobile ? 'footnote' : 'caption'}
                            weight="extraBold"
                            lineHeight={1}
                            content={(index + 1).toString()}
                            style={{ color: '#D8D8D8' }}
                          />
                        )}
                        <Text
                          outset={{ left: isCurrent ? 'x0' : 'x1' }}
                          type="caption"
                          weight="semiBold"
                          lineHeight={1}
                          whiteSpace="nowrap"
                          style={{
                            fontSize: isMobile ? '10px' : '13px',
                            color: isCompleted
                              ? '#fff'
                              : isCurrent
                                ? '#333'
                                : '#D8D8D8',
                          }}
                        >
                          {stepName === 'Addon' ? 'Add on' : stepName}
                        </Text>
                      </Flex>
                    )
                  })}
              </Flex>
            </Flex>

            {/* --------- BACK BUTTON --------- */}
            {step !== Steps.Subscription && (
              <Flex
                width="100%"
                onClick={onBackAddon}
                columnGap="x_5"
                alignItems="center"
                outset={{ bottom: 'x1' }}
              >
                <Icon name="chevron" size={16} color="#333333" />
                <Text
                  type="footnote"
                  content="Back"
                  style={{
                    textDecoration: 'underline',
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#333333',
                  }}
                />
              </Flex>
            )}
            <Text
              type="largeBody"
              weight="semiBold"
              alignment="left"
              outset={{ vertical: '8px' }}
              style={{ fontSize: '14px' }}
              content={getContent()}
            />
            {/* --------- CONDITIONAL STEPS --------- */}
            {isLoading && (
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="center"
                inset={{ vertical: 'x6' }}
              >
                <LoadingSpinner size="xLarge" />
              </Flex>
            )}
            {!isLoading && isStepActive(Steps.Subscription) && (
              <StepSubscription
                onSkip={handleSkip}
                onContinue={onContinueSubscription}
                premiumSubVariantIds={premiumSubVariantIds}
                isMobile={isMobile}
              />
            )}
            {!isLoading && isStepActive(Steps.Addon) && (
              <StepAddOn
                addonVariantIds={addonVariantIds}
                isMobile={isMobile}
                onBack={onBackAddon}
                onContinue={onContinueAddon}
                onSkip={() => setStep(Steps.Confirmation)}
              />
            )}
            {!isLoading && isStepActive(Steps.Confirmation) && (
              <StepConfirmation
                onAddToCartAndContinue={onAddToCartAndContinueShopping}
                onContinue={onAddToCartAndCheckout}
                packagePlan={selectedPackagePlan}
                selectedAddons={selectedAddons}
                selectedPlan={selectedPlan}
                isMobile={isMobile}
              />
            )}
          </div>
        </Flex>
      </div>
    </Portal>
  )
}
