import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { useFormatSOCMediaUrl } from 'src/hooks/useFormatSOCMediaUrl'

type ActUpsaleDialogProps = {
  isOpen: boolean
  title: string
  description: string
  onClose: () => void
  primaryAction: {
    title: string
    onClick: () => void
  }
  background?: string
  socMediaImage: { filename: string; extension?: string }
}

export const ActUpsaleDialog = (props: ActUpsaleDialogProps) => {
  const {
    isOpen,
    primaryAction,
    background = 'linear-gradient(to bottom, #F6F6F600, #F6F6F6, #F6F6F6),linear-gradient(to right, #FFDFE7, #E498FD)',
    title,
    description,
    onClose,
    socMediaImage,
  } = props
  const fontFamily = 'Montserrat, sans-serif'

  const titleStyle = {
    fontFamily: fontFamily,
    fontSize: '28px',
    fontWeight: 700,
    color: '#252525',
  }

  const descriptionStyles = {
    fontFamily: fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    color: '#404040',
  }

  const buttonStyles = {
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
    padding: '8px 20px',
    fontFamily: fontFamily,
    alignItems: 'center',
    fontWeight: 600,
    color: '#fff',
    fontSize: '16px',
    backgroundColor: '#404040',
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} sheetBgColorOverride={background}>
      <Flex
        flexDirection="column"
        rowGap="x2"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            backgroundImage: `url(${useFormatSOCMediaUrl(
              socMediaImage.filename,
              socMediaImage.extension,
            )})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            maxWidth: '600px',
            minWidth: '300px',
            aspectRatio: '1/1',
          }}
        />
        <Text type="body" style={{ ...titleStyle }} content={title} />
        <Text
          type="body"
          style={{ ...descriptionStyles }}
          content={description}
        />
        <div onClick={primaryAction.onClick} style={{ ...buttonStyles }}>
          {primaryAction.title}
        </div>
      </Flex>
    </Dialog>
  )
}
