import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  AccountFragment,
  AccountSettingsInput,
  AddressInput,
} from 'src/graphql/generated/graphql'
import { useAccount, useActions } from 'src/hooks'
import { queryKeyStore } from '../queryKeyStore'
import {
  addFreeCampaign,
  cancelStripeSubscription,
  changeSubscription,
  createAccount,
  createAffiliate,
  createCampaign,
  createCard,
  createContactRequest,
  createFreeAffiliate,
  createOrder,
  createOrUpdateBackPanel,
  createSparseUser,
  createUserLoginLink,
  deleteContactRequest,
  getOrCreateEditableCardForRecipient,
  loginUser,
  purchaseProduct,
  revertBulkOrder,
  sendMagicLink,
  updateAccount,
  updateCampaign,
  updateCard,
  updateOrder,
  updateRequestedContact,
  updateUserLabelOverrides,
  upgradeToAffiliate,
  upgradeToGreenerStillAffiliate,
  upgradeToReferringCustomer,
  validateEmailVerificationCode,
  verifyPhoneNumber,
} from './fetching'
import * as fetching from './fetching'

export const useAcceptCampaignShare = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.acceptCampaignShare,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useAcceptSharedContact = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.acceptSharedContact,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useDeleteCampaignShare = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.deleteCampaignShare,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useDeleteContactShare = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.deleteContactShare,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useDeleteReminder = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.deleteReminder,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useDenyNotification = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: fetching.denyNotification,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.alerts.infinite._def)
    },
  })
  return mutation
}

export const useCancelStripeSubscription = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: cancelStripeSubscription,
    onSuccess: data => {
      if (data.cancelStripeSubscription.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.cancelStripeSubscription.account,
        )
      }
    },
  })
  return mutation
}

export const useCreateAccount = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: createAccount,
    onSuccess: data => {
      if (data.createAccount.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.createAccount.account,
        )
      }
    },
  })

  return mutation
}

export const useCreateAffiliate = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createAffiliate,
    onSuccess: data => {
      if (data.createDistributor.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.createDistributor.account,
        )
      }
    },
  })

  return mutation
}

export const useCreateFreeAffiliate = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createFreeAffiliate,
    onSuccess: data => {
      if (data.createFreeAffiliate.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.createFreeAffiliate.account,
        )
      }
    },
  })

  return mutation
}

export const useCreateUserLoginLink = () => {
  return useMutation({ mutationFn: createUserLoginLink })
}

export const usePhoneNumberVerification = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: verifyPhoneNumber,
    onSuccess: data => {
      if (
        data.verifyPhoneNumber.__typename === 'VerifyPhoneNumberSuccess' &&
        data.verifyPhoneNumber &&
        data.verifyPhoneNumber.verifiedAccount
      ) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.verifyPhoneNumber.verifiedAccount,
        )
      }
    },
  })
  return mutation
}

export const useValidateEmailVerification = () => {
  return useMutation({
    mutationFn: validateEmailVerificationCode,
  })
}

export const useMagicLink = () => {
  return useMutation({ mutationFn: sendMagicLink })
}

export const useLoginUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: loginUser,
    onSuccess: data => {
      if (data.loginUser.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.loginUser.account,
        )
      }
    },
  })
}

export const useCreateCard = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createCard,
    onSuccess: data => {
      if (data.createCard.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.createCard.account,
        )
      }
    },
  })
}

export const useDigitalCardCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: fetching.digitalCardCreate,
    onSuccess: data => {
      if (data?.card) {
        queryClient.invalidateQueries(
          queryKeyStore.card.detail({
            id: data.card.id,
          }),
        )
      }
    },
  })
}

export const useDigitalCardOrderCreate = () => {
  return useMutation({
    mutationFn: fetching.digitalCardOrderCreate,
  })
}

export const useDigitalCardOrderPay = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: fetching.digitalCardOrderPay,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.card._def)
    },
  })
}

export const useDigitalCardUpdate = () => {
  return useMutation({
    mutationFn: fetching.digitalCardUpdate,
  })
}

export const useDigitalCardRecipientCreate = () => {
  return useMutation({
    mutationFn: fetching.digitalCardRecipientCreate,
  })
}

export const useDeleteDigitalCard = () => {
  return useMutation({
    mutationFn: fetching.digitalCardDelete,
  })
}

export const useRequestPhysicalCard = () => {
  return useMutation({
    mutationFn: fetching.requesPhysicalCard,
  })
}

export const useCreateSparseUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createSparseUser,
    onSuccess: data => {
      if (data.createSparseUser.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.createSparseUser.account,
        )
      }
    },
  })
}

export const usePurchaseProduct = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: purchaseProduct,
    onSuccess: data => {
      if (data.purchaseProduct.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.purchaseProduct.account,
        )
      }
    },
  })
}

export const useUpgradeToAffiliate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: upgradeToAffiliate,
    onSuccess: data => {
      if (data.upgradeToAffiliate.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.upgradeToAffiliate.account,
        )
      }
    },
  })
}

export const useUpgradeToGreenerStillAffiliate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: upgradeToGreenerStillAffiliate,
    onSuccess: data => {
      if (data.upgradeToGreenerStillAffiliate.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.upgradeToGreenerStillAffiliate.account,
        )
      }
    },
  })
}

export const useUpgradeToReferringCustomer = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: upgradeToReferringCustomer,
    onSuccess: data => {
      if (data.upgradeToReferringCustomer.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.upgradeToReferringCustomer.account,
        )
      }
    },
  })
}

export const useUpdateAccount = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateAccount,
    onSuccess: data => {
      if (data.updateAccount.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.updateAccount.account,
        )
      }
    },
  })
}

export const useUpdateAccountSettings = () => {
  const queryClient = useQueryClient()
  const queryKey = queryKeyStore.account.detail.queryKey

  return useMutation({
    mutationFn: (variables?: AccountSettingsInput) =>
      updateAccount({ account: { settings: variables } }),
    onMutate: async variables => {
      await queryClient.cancelQueries({ queryKey })
      const accountSnapShot =
        queryClient.getQueryData<AccountFragment>(queryKey)
      if (accountSnapShot) {
        queryClient.setQueryData<AccountFragment>(queryKey, old => {
          if (old && old.settings) {
            const settings = {
              defaultBackPanel: variables?.defaultBackPanelId
                ? { id: variables?.defaultBackPanelId }
                : (old.settings.defaultBackPanel ?? null),
              shouldPrintRecipientCompany:
                variables?.printRecipientCompany ??
                old.settings?.shouldPrintRecipientCompany,
              shouldReceiveNotificationEmails:
                variables?.receivesNotificationEmails ??
                old.settings?.shouldReceiveNotificationEmails,
              shouldReceivePromoEmails:
                variables?.receivesPromoEmails ??
                old.settings?.shouldReceivePromoEmails,
              shouldReceiveReceiptEmails:
                variables?.receivesReceiptEmails ??
                old.settings?.shouldReceiveReceiptEmails,
              shouldReceiveReminderEmails:
                variables?.receivesReminderEmails ??
                old.settings?.shouldReceiveReminderEmails,
              remindersAdvanceNoticeDays:
                variables?.remindersAdvanceNoticeDays ??
                old.settings?.remindersAdvanceNoticeDays,
              remindersAdvanceNoticeDaysInternational:
                variables?.remindersAdvanceNoticeDaysInternational ??
                old.settings?.remindersAdvanceNoticeDaysInternational,
            }

            return {
              ...old,
              settings: { ...old.settings, ...settings },
            }
          }
          return undefined
        })
      }
      return { accountSnapShot }
    },
    onSettled: (data, error, _variables, context) => {
      if (data?.updateAccount) {
        queryClient.setQueryData<AccountFragment>(
          queryKey,
          data.updateAccount.account,
        )
      } else {
        if (context?.accountSnapShot) {
          queryClient.setQueryData<AccountFragment>(
            queryKey,
            context?.accountSnapShot,
          )
        }
      }
    },
  })
}

export const useUpdateCard = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateCard,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(
        queryKeyStore.card.detail({ id: variables.card.id }),
      )
    },
  })
}

export const useGetOrCreateEditableCardForRecipient = () => {
  return useMutation({ mutationFn: getOrCreateEditableCardForRecipient })
}

export const useUpdateUserLabelOverrides = () => {
  return useMutation({ mutationFn: updateUserLabelOverrides })
}

export const useUpdateCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateCampaign,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(
        queryKeyStore.campaign.detail({ id: variables.campaign.id }),
      )
    },
  })
}

export const useCreateOrder = () => useMutation({ mutationFn: createOrder })

export const useCreateCampaign = () =>
  useMutation({ mutationFn: createCampaign })

export const useUpdateOrder = () => useMutation({ mutationFn: updateOrder })

export const useUpdateProductionInfo = () =>
  useMutation({ mutationFn: fetching.updateProductionInfo })

export const useRevertBulkOrder = () => {
  const account = useAccount()
  const actions = useActions()
  const updateOrderMutation = useUpdateOrder()

  return useMutation({
    mutationFn: revertBulkOrder,
    onSuccess: async (data, variables) => {
      actions.savedOrder(data.revertBulkOrder.order)

      if (account.shippingAddress) {
        const returnAddress: AddressInput = {
          firstName: account.shippingAddress.firstName,
          lastName: account.shippingAddress.lastName,
          company: account.shippingAddress.company,
          address1: account.shippingAddress.address1,
          address2: account.shippingAddress.address2,
          city: account.shippingAddress.city,
          state: account.shippingAddress.state,
          postalCode: account.shippingAddress.postalCode,
          country: account.shippingAddress.country,
        }
        const { updateOrder } = await updateOrderMutation.mutateAsync({
          order: { id: variables.id, returnAddress: returnAddress },
        })

        if (updateOrder.order) {
          actions.savedOrder(updateOrder.order)
        }
      }
    },
  })
}

export const useCreateOrUpdateBackPanel = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createOrUpdateBackPanel,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.layout._def)
    },
  })
}

export const useDeletePanelLayout = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: fetching.deletePanelLayout,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.layout._def)
    },
  })
}

export const useAddFreeCampaign = () =>
  useMutation({ mutationFn: addFreeCampaign })

export const useChangeSubscription = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: changeSubscription,
    onSuccess: data => {
      if (data.changeSubscription.account) {
        queryClient.setQueryData<AccountFragment>(
          queryKeyStore.account.detail.queryKey,
          data.changeSubscription.account,
        )
      }
    },
  })
}

export const useCreateContactRequest = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createContactRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.contact_requests.list)
    },
  })
}

export const useDeleteContactRequest = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteContactRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyStore.contact_requests.list)
    },
  })
}

export const useUpdateRequestedContact = () =>
  useMutation({
    mutationFn: updateRequestedContact,
  })
