import React from 'react'
import { Div, Flex, Separator, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'

export type TotalsTableProps = {
  subtotal: string
  taxTotal?: string
  orderTotal: string
  discounts?: {
    name: string
    amount: string
  }
  recurringAmount?: { amount: string; occurrence: string }
}

type PricingLineProps = {
  title: string
  price?: string
  icon?: { name: IconType; color?: string; size?: number }
  discounts?: {
    name: string
    amount: string
  }
  shouldRemovePadding?: boolean
  fontSettings?: {
    size?: 'body' | 'footnote' | 'caption'
    color?: string
  }
}
const PricingLine: React.FC<PricingLineProps> = ({
  title,
  price,
  icon,
  shouldRemovePadding,
  fontSettings,
}) => {
  return (
    <Flex
      width="100%"
      inset={!shouldRemovePadding ? { vertical: 'x1' } : undefined}
      alignItems={'center'}
      columnGap="x_5"
    >
      {icon && <Icon name={icon.name} size={icon.size} color={icon.color} />}
      <Div>
        <Text
          content={title}
          type={fontSettings?.size ?? 'body'}
          color={'primaryHeading'}
          weight="bold"
          style={{ color: fontSettings?.color }}
        />
      </Div>
      {price && (
        <Div style={{ marginLeft: 'auto' }}>
          <Text
            content={price}
            type={fontSettings?.size ?? 'body'}
            color="primaryHeading"
            weight="bold"
            style={{ color: fontSettings?.color }}
          />
        </Div>
      )}
    </Flex>
  )
}

export const TotalsTable = (props: TotalsTableProps) => {
  const { subtotal, discounts, taxTotal, orderTotal, recurringAmount } = props
  return (
    <Flex
      backgroundColor="foreground"
      flexDirection="column"
      width="100%"
      borderRadius="large"
      inset={{ vertical: 'x1', horizontal: 'x1' }}
    >
      <Flex
        flexDirection="column"
        backgroundColor="background"
        inset="x2"
        style={{ borderRadius: '20px' }}
      >
        <PricingLine title={'Subtotal'} price={`$${subtotal}`} />
        {discounts && (
          <>
            <PricingLine
              title={'Discounts & Savings'}
              shouldRemovePadding={false}
            />
            <PricingLine
              shouldRemovePadding
              icon={{ name: 'premium_crown', size: 16, color: '#FECD39' }}
              title={
                discounts.name === 'ActToken' ? '1 Act Token' : discounts.name
              }
              price={`-$${discounts.amount}`}
              fontSettings={{
                size: 'footnote',
                color: 'gray',
              }}
            />
          </>
        )}
        {taxTotal && <PricingLine title={'Taxes'} price={`$${taxTotal}`} />}
      </Flex>
      <Flex flexDirection="column" inset="x2" style={{ paddingBottom: '5px' }}>
        <PricingLine title={'Total Due Today'} price={`$${orderTotal}`} />
        {recurringAmount && (
          <>
            <Div inset={{ horizontal: 'x1' }} outset={{ vertical: 'x1' }}>
              <Separator orientation="horizontal" />
            </Div>
            <PricingLine
              title="Total Ongoing"
              price={`$${recurringAmount.amount}/${recurringAmount.occurrence}`}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}
