import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CardFragment } from 'src/graphql/generated/graphql'
import ActCardGraphic from '../../ActCardGraphic/ActCardGraphic'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

export type DigitalCardDisplayProps = {
  size?: number
  shouldHideDevice?: boolean
  card: CardFragment
}

export const DigitalCardDisplay = (props: DigitalCardDisplayProps) => {
  const { card } = props

  return (
    <Flex position="relative" justifyContent="center" alignItems="center">
      <Flex
        borderRadius="circle"
        width="28px"
        height="28px"
        style={{ backgroundColor: '#EBF7F3' }}
        position="absolute"
        bottom={card.isHorizontal ? '18px' : '-12px'}
        right={card.isHorizontal ? '6px' : '12px'}
        justifyContent="center"
        alignItems="center"
        zIndex={1}
      >
        <Icon name="check" size={16} />
      </Flex>
      <ActCardGraphic
        isHorizontal={card.isHorizontal}
        cardPreviewUrl={card.frontPreviewUrl}
        shouldShowGradientBackground={false}
        shouldShowCellphone={true}
        shouldShowEnvelope={false}
        height="180px"
      />
    </Flex>
  )
}
