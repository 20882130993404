import { ThreeDCardPreview } from '@sendoutcards/editor'
import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { MinimalCardPreviewFragment } from 'src/graphql/generated/graphql'
import {
  mapPanelControlNames,
  mapPanelImages,
} from 'src/helpers/threeDCard/mapPanelImages'
import { useSelector } from 'src/hooks'

export interface PriceProps {
  card?: MinimalCardPreviewFragment
  onClose: () => void
  isOpen?: boolean
  title?: string
}

const Simple3DPreviewDialog: React.FC<PriceProps> = props => {
  const { card, onClose, isOpen = true, title } = props
  const isMobile = useSelector(state => state.window.width < 500)

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      wrapperStyle={{ overflow: 'hidden' }}
      title={title}
      padding={!isMobile ? '80px 120px 40px' : undefined}
    >
      {card && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '80px',
          }}
        >
          <ThreeDCardPreview
            cardType={card.type}
            orientation={card.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT'}
            panels={mapPanelImages(
              card.panels,
              !!card.flapPreviewUrl,
              card.isHorizontal,
            )}
            bgGradientColor={{ shouldDisplayBg: false }}
            height="600px"
            maxHeight="80vw"
            control={{
              type: 'Standard',
              panelNames: mapPanelControlNames(card.panels),
              shouldOnlyShowDropdown: isMobile,
            }}
          />
        </div>
      )}
    </Dialog>
  )
}

export default Simple3DPreviewDialog
