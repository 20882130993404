import React from 'react'
import { Flex, LoadingSpinner, Text } from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import { useState } from 'src/hooks'
import { SubscriptionSelection } from './SubscriptionSelection'
import { useProductVariants } from 'src/react_query/queries/hooks'
import { PlanIdType } from '../pricingTile/PlanTiles'
import { PlanFragment } from 'src/graphql/generated/graphql'

type StepSubscriptionProps = {
  premiumSubVariantIds: string[]
  isMobile: boolean
  onSkip: () => void
  onContinue: (plan: PlanFragment) => void
}

export const StepSubscription: React.FC<StepSubscriptionProps> = ({
  isMobile,
  premiumSubVariantIds,
  onSkip,
  onContinue,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanFragment | undefined>()

  const handlePlanSelection = (plan: PlanFragment) => {
    setSelectedPlan(plan)
  }

  const { data: premiumSubs } = useProductVariants({
    first: 100,
    ids: premiumSubVariantIds,
  })

  const pSubsIds: PlanIdType[] =
    premiumSubs?.pages
      ?.flatMap(page =>
        page?.edges.map(edge => {
          const planId = edge.node.metadata.find(
            meta => meta.key === 'plan_id',
          )?.value
          return planId ? { id: planId } : undefined
        }),
      )
      ?.filter((plan): plan is PlanIdType => plan !== undefined) ?? []

  return (
    <>
      <Flex inset={{ horizontal: '2px' }}>
        <Flex flexDirection="column" width="100%" rowGap="x1">
          <Text
            type="caption"
            weight="bold"
            content="Subscription Plans:"
            style={{ color: '#333333' }}
            outset={{ bottom: 'x1' }}
          />

          {pSubsIds.length > 0 ? (
            <SubscriptionSelection
              hasPill={{
                text: 'Free 1st Month',
                backgroundColor: '#fff',
                textColor: '#333',
              }}
              planIds={pSubsIds}
              onSelectPlan={handlePlanSelection}
            />
          ) : (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              inset="x6"
            >
              <LoadingSpinner size="xLarge" />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        position="sticky"
        bottom={0}
        rowGap={'x1'}
        flexDirection={isMobile ? 'column' : 'row'}
        inset={{ horizontal: isMobile ? 'x0' : 'x4', bottom: 'x2' }}
        style={{
          position: 'sticky',
          bottom: 0,
          right: isMobile ? 20 : undefined,
          backgroundColor: 'transparent',
          backdropFilter: 'blur(20px)',
          WebkitBackdropFilter: 'blur(20px)',
          overflow: 'hidden',
          width: '100%',
          justifyContent: isMobile ? 'center' : 'flex-end',
          columnGap: '16px',
          paddingTop: '8px',
          marginInline: '2px',
        }}
      >
        <div onClick={onSkip} style={{ cursor: 'pointer' }}>
          <Text
            type="body"
            weight="semiBold"
            style={{
              textDecoration: 'underline',
              fontSize: 12,
              fontWeight: 700,
            }}
            content="Skip this step"
          />
        </div>
        <Button
          title="Yes, I confirm"
          backgroundColor="#404040"
          textColor="#FFF"
          onClick={() => {
            if (selectedPlan) onContinue(selectedPlan)
          }}
          isDisabled={!selectedPlan}
        />
      </Flex>
    </>
  )
}
