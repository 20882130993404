import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type ReicipientType = {
  firstname: string
  lastname: string
  imageUrl?: string
}

type ActionType = {
  title: string
  onClick: () => void
}

export type RecipientViewProps = {
  title?: string
  description?: string
  recipient: ReicipientType
  primaryAction?: ActionType
  secondaryAction?: ActionType
}

export const RecipientView = (props: RecipientViewProps) => {
  const { title, description, recipient, primaryAction, secondaryAction } =
    props
  const fontFamily = 'Montserrat, sans-serif'

  const titleStyles = {
    fontFamily: fontFamily,
    fontSize: '18px',
    fontWeight: 700,
    color: '#404040',
  }

  const descriptionStyles = {
    fontFamily: fontFamily,
    fontSize: '16px',
    fontWeight: 500,
    color: '#404040',
    lineHeight: 1.2,
  }

  const labelStyles = {
    color: '#B1B1B1',
    fontFamily: fontFamily,
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: 1.2,
  }

  const nameStyles = {
    color: '#11181C',
    fontSize: '16px',
    fontFamily: fontFamily,
    fontWeight: 500,
  }

  const imageStyles = {
    aspectRatio: '1/1',
    width: '100%',
    maxWidth: '60px',
    maxHeight: '60px',
    backgroundImage: `url(${recipient?.imageUrl})`,
    backgroundPostition: 'center',
    backgroundSize: 'cover',
    backgrounRepeat: 'no-repeat',
    borderRadius: '5px',
  }

  const initialTileStyles = {
    borderRadius: '5px',
    aspectRatio: '1/1',
    width: '100%',
    maxWidth: '60px',
    maxHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eeefef',
    fontFamily: fontFamily,
    fontWeight: 600,
    fontSize: '20px',
    color: '#5b5b5b',
  }

  const actionSharedStyles = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row' as const,
    columnGap: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    width: '100%',
    padding: '12px 8px',
    fontFamily: fontFamily,
    fontSize: '16px',
    fontWeight: 500,
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
  }

  const recipientInitals = `${recipient.firstname[0] + recipient.lastname[0]}`

  return (
    <Flex flexDirection="column" rowGap="x2" width="100%">
      <Text type="body" content={title} style={{ ...titleStyles }} />
      <Text
        type="body"
        content={description}
        style={{ ...descriptionStyles }}
      />
      <Flex flexDirection="row" columnGap="x1">
        {recipient.imageUrl ? (
          <div style={{ ...imageStyles }} />
        ) : (
          <div style={{ ...initialTileStyles }}>{recipientInitals}</div>
        )}
        <Flex flexDirection="column" justifyContent="center">
          <Text type="body" content="FROM:" style={{ ...labelStyles }} />
          <Text
            type="body"
            content={`${recipient?.firstname} ${recipient?.lastname}`}
            style={{ ...nameStyles }}
          />
        </Flex>
      </Flex>
      {primaryAction && (
        <div
          onClick={primaryAction?.onClick}
          style={{
            ...actionSharedStyles,
            background:
              'linear-gradient(90deg, #A168E9 -20.58%, #D67FFF 117.96%), #FFF',
          }}
        >
          <Icon name="copyRequest" size={20} color={actionSharedStyles.color} />
          {primaryAction?.title}
        </div>
      )}
      {secondaryAction && (
        <div
          onClick={secondaryAction?.onClick}
          style={{
            ...actionSharedStyles,
            background: 'linear-gradient(0deg, #444 0%, #444 100%), #FFF',
          }}
        >
          <Icon
            name="card_w_heart"
            size={22}
            color={actionSharedStyles.color}
          />
          {secondaryAction?.title}
        </div>
      )}
    </Flex>
  )
}
