import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { DigitalCardDisplay } from './components/DigitalCardDisplay'
import { CardFragment } from 'src/graphql/generated/graphql'
import { useActions, useSelector, useState } from 'src/hooks'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { LEGACY_SITE_URL } from 'src/app/api'

type ActConfirmationScreenProps = {
  onFinish: () => void
  card?: CardFragment
  onCopyToClipboard?: () => boolean
  token?: string
  recipientName?: string
  messageTitle?: string
  messageBody?: string
  url: string
  isSparse: boolean
}

export const ActConfirmationScreen = ({
  onFinish,
  card,
  onCopyToClipboard,
  token,
  recipientName,
  messageTitle,
  messageBody,
  url,
  isSparse,
}: ActConfirmationScreenProps) => {
  const title = 'Great Job!' // TODO: make marketing paragraph
  const description = `Your ACT of Gratitude is sure to brighten ${recipientName} day` // TODO: make marketingparagraph

  const baseURL = LEGACY_SITE_URL

  const fontFamily = 'Montserrat, sans-serif'
  const titleStyles = {
    color: '#252525',
    fontFamily: fontFamily,
    fontWeight: 700,
    fontsize: '24px',
  }

  const isMobile = useSelector(state => state.window.width < 540)
  const isSmallMobile = useSelector(state => state.window.width < 380)
  const mainContainerMaxWidth = '700px'
  const actions = useActions()

  const [shouldShowMessage, setShouldShowMessage] = useState(false)
  const [hasCopiedShareLink, setHasCopiedShareLink] = useState(false)

  const handleCopyToClipboard = () => {
    if (onCopyToClipboard) {
      const isSuccessful = onCopyToClipboard()
      setHasCopiedShareLink(isSuccessful)

      if (isSuccessful) {
        setTimeout(() => {
          setHasCopiedShareLink(false)
        }, 3000)
      }
    }
  }

  return (
    <Flex
      borderRadius="16px"
      flexDirection="column"
      rowGap="x4"
      alignItems="center"
      backgroundColor="#fff"
      maxWidth="900px"
      overflowY="scroll"
      width="100vw"
      height="calc(100vh - 32px)"
      inset="16px"
      minWidth="300px"
    >
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Flex
          width="40vw"
          maxWidth="250px"
          justifyContent="center"
          alignItems="center"
        >
          <Icon name={'promptings_powered_by_soc_logo'} />
        </Flex>
        {!isSparse && (
          <Flex
            justifyContent="center"
            alignItems="center"
            onClick={() => actions.openAccount()}
            cursor="pointer"
          >
            <Text
              whiteSpace="nowrap"
              content="Go to Dashboard"
              style={{
                color: '#6B7280',
                fontSize: isMobile ? 12 : 14,
                fontWeight: 600,
                textDecoration: 'underline',
              }}
              type={'body'}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        width="220px"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          type="body"
          alignment="center"
          content={title}
          style={{ ...titleStyles }}
        />
        <Text
          type="body"
          outset={{ top: 'x_5' }}
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: '#6B7280',
            lineHeight: 1.5,
          }}
          alignment="center"
          content={description}
        />
      </Flex>
      {card && (
        <DigitalCardDisplay
          card={card}
          size={
            isSmallMobile
              ? card.isHorizontal
                ? 72
                : 56.8
              : card.isHorizontal
                ? 144
                : 113.6
          }
        />
      )}
      <Flex
        flexDirection="column"
        width="100%"
        maxWidth={mainContainerMaxWidth}
      >
        <Flex
          flexDirection="row"
          backgroundColor="#fff"
          style={{
            borderRadius: 12,
          }}
          inset={{ horizontal: 'x2', vertical: 'x_5' }}
        >
          <Text
            type="body"
            content={'Recipient:'}
            style={{
              fontSize: 12,
              fontWeight: 700,
              fontFamily: fontFamily,
            }}
          />
          <Text
            type="body"
            outset={{ left: 'x1' }}
            content={recipientName}
            style={{
              fontSize: 12,
              fontWeight: 500,
              fontFamily: fontFamily,
            }}
          />
        </Flex>
        <Flex
          width="100%"
          flexWrap="wrap"
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={() => setShouldShowMessage(!shouldShowMessage)}
          inset={{ horizontal: 'x2' }}
          cursor="pointer"
        >
          <Text
            type="body"
            style={{
              fontSize: 12,
              fontWeight: 700,
              fontFamily: fontFamily,
            }}
            content="Customized Message"
            weight="bold"
            outset={{ vertical: 'x_5' }}
          />

          <Flex
            justifyContent="center"
            alignItems="center"
            style={{
              transform: `rotate(${shouldShowMessage ? 270 : 90}deg)`,
            }}
          >
            <Icon name="chevron" size={24} color="#202020" />
          </Flex>
        </Flex>
        {shouldShowMessage && (
          <Flex inset={{ left: 'x2' }} width="100%" flexDirection="column">
            <Text
              style={{ fontSize: '12px', fontWeight: 500 }}
              type="caption"
              content={messageTitle}
              weight="semiBold"
              inset={{ left: 'x_5' }}
            />
            <Text
              style={{ fontSize: '12px', fontWeight: 500 }}
              type="caption"
              content={messageBody}
              weight="semiBold"
              inset={{ left: 'x_5', bottom: 'x_5' }}
            />
          </Flex>
        )}
      </Flex>
      <Flex
        width="100%"
        justifyContent="space-evenly"
        maxWidth={mainContainerMaxWidth}
        alignItems={isMobile ? 'start' : 'center'}
        minHeight="50px"
        borderRadius="14px"
        backgroundColor="#F9FAFB"
        style={{ border: '16px solid #F9FAFB', scrollbarWidth: 'none' }}
        rowGap="1rem"
        flexDirection={isMobile ? 'column' : 'row'}
        inset={{ horizontal: isMobile ? 'x1_5' : 'x0' }}
        overflowY="scroll"
      >
        <Text
          type={'title'}
          whiteSpace="normal"
          alignment={isMobile ? 'left' : 'center'}
          style={{
            fontSize: isSmallMobile ? 12 : 14,
            fontWeight: 700,
            lineHeight: 1.4,
          }}
          content={url}
        />
      </Flex>
      {!!onCopyToClipboard && (
        <Flex
          width="100%"
          justifyContent="space-evenly"
          alignItems="center"
          columnGap="2rem"
          maxWidth={mainContainerMaxWidth}
          rowGap="1rem"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Flex
            width="100%"
            minWidth="230px"
            maxWidth="350px"
            onClick={handleCopyToClipboard}
            justifyContent="center"
            alignContent="center"
            style={{
              borderRadius: '50px',
              backgroundColor: '#EFFAFF',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '14px 36px',
              fontFamily: fontFamily,
              color: '#6B7280',
              cursor: 'pointer',
            }}
          >
            <Icon
              name={hasCopiedShareLink ? 'check' : 'copy'}
              width={17}
              height={16}
              color="#0090D4"
              size={hasCopiedShareLink ? 15 : undefined}
            />
            <Text
              outset={{ left: 'x_5' }}
              type={'body'}
              whiteSpace="nowrap"
              style={{
                color: '#0090D4',
                fontSize: 12,
                fontWeight: 700,
              }}
              weight="bold"
              content={
                hasCopiedShareLink ? 'SHARE LINK COPIED' : 'COPY SHARE LINK'
              }
            />
          </Flex>
          <Flex
            width="100%"
            minWidth="180px"
            maxWidth="350px"
            onClick={() => {
              navigator.share({
                title: card?.digitalCard?.shareMessageTitle ?? '',
                text: card?.digitalCard?.shareMessageBody ?? '',
                url: `${baseURL}/act/${token}`,
              })
            }}
            style={{
              borderRadius: '50px',
              backgroundColor: '#FAF5FF',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '14px 36px',
              fontFamily: fontFamily,
              color: '#6B7280',
              fontSize: '10px',
              fontWeight: 700,
              cursor: 'pointer',
            }}
          >
            <Icon name="share" width={17} height={16} />
            <Text
              outset={{ left: 'x_5' }}
              type={'body'}
              whiteSpace="nowrap"
              style={{ color: '#7E22CE', fontSize: 12, fontWeight: 700 }}
              weight="bold"
              content="SHARE" //TODO: SHARE AGAIN once we force share to show first visit
            />
          </Flex>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          width="100%"
          onClick={onFinish}
          outset={{ bottom: 'x1_5' }}
          maxWidth={mainContainerMaxWidth}
          style={{
            borderRadius: '16px',
            backgroundColor: '#262626',
            color: '#fff',
            padding: '12px',
            width: '100%',
            fontFamily: fontFamily,
            fontSize: '16px',
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          Done
        </Flex>
        {!isSparse && (
          <Flex
            justifyContent="center"
            alignItems="center"
            onClick={actions.openCatalog}
            cursor="pointer"
          >
            <Text
              whiteSpace="nowrap"
              content="Send another Card"
              style={{
                color: '#6B7280',
                fontSize: isMobile ? 12 : 14,
                fontWeight: 600,
                textDecoration: 'underline',
              }}
              type={'body'}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
