import React from 'react'
import styles from './actCardGraphic.module.scss'
import { Variants, motion } from 'framer-motion'
import { useState } from 'src/hooks'

type ActCardGraphicProps = {
  cardPreviewUrl: string
  isHorizontal: boolean
  /**
   * Keep in mind that this graphic has a 1/1 aspect ratio. Where a vertical card
   * will use about all available height, and a horizontal card  will use about all
   * available horizontal width
   */
  height?: string
  shouldShowGradientBackground?: boolean
  shouldShowEnvelope?: boolean
  shouldShowCellphone?: boolean
  topBadge?: {
    node: React.ReactNode
    variants?: Variants
  }
  bottomBadge?: {
    node: React.ReactNode
    variants?: Variants
  }
}

const ActCardGraphic: React.FC<ActCardGraphicProps> = props => {
  const {
    cardPreviewUrl,
    isHorizontal,
    height = '300px',
    shouldShowGradientBackground,
    shouldShowEnvelope,
    shouldShowCellphone,
    topBadge,
    bottomBadge,
  } = props

  const [isEnvelopeLoaded, setIsEnvelopeLoaded] = useState(false)
  const [isCardPreviewLoaded, setIsCardPreviewLoaded] = useState(false)
  const [isCellphoneLoaded, setIsCellphoneLoaded] = useState(false)

  const envelopeVariants = {
    enter: {
      opacity: 1,
      x: '0%',
      transition: {
        duration: 0.4,
        delay: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: '-100%',
    },
  }

  const cellphoneVariants = {
    enter: {
      opacity: 1,
      x: '0%',
      transition: {
        duration: 0.4,
        delay: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: '100%',
    },
  }

  const cardVariants = {
    enter: {
      opacity: 1,
      x: '0%',
      transition: {
        duration: 0.4,
        delay: 0.6,
      },
    },
    exit: {
      opacity: 0,
      x: '-100%',
    },
  }

  const shouldEnterCard =
    ((shouldShowCellphone && isCellphoneLoaded) || !shouldShowCellphone) &&
    ((shouldShowEnvelope && isEnvelopeLoaded) || !shouldShowEnvelope) &&
    isCardPreviewLoaded

  return (
    <div
      className={`${styles.wrapper} ${shouldShowGradientBackground ? styles.gradientBg : ''} ${isHorizontal ? styles.horizontal : ''}`}
      style={{ height: height }}
    >
      {shouldShowEnvelope && (
        <motion.div
          className={`${styles.inners} ${styles.envelope}`}
          variants={envelopeVariants}
          initial="exit"
          exit="exit"
          animate={isEnvelopeLoaded ? 'enter' : 'exit'}
        >
          <img
            src="https://www.sendoutcards.com/media/images/TileEnvelope.png"
            alt="promptings sendoutcards envelope"
            className={`${styles.img} ${styles.envelopeImg}`}
            onLoad={() => {
              setIsEnvelopeLoaded(true)
            }}
          />
        </motion.div>
      )}
      {shouldShowCellphone && (
        <motion.div
          className={`${styles.inners} ${styles.cellphone}`}
          variants={cellphoneVariants}
          initial="exit"
          exit="exit"
          animate={isCellphoneLoaded ? 'enter' : 'exit'}
        >
          <img
            src="https://www.sendoutcards.com/media/images/iPhone.png"
            alt="promptings sendoutards phone"
            className={styles.img}
            onLoad={() => {
              setIsCellphoneLoaded(true)
            }}
          />
        </motion.div>
      )}
      <motion.div
        className={`${styles.inners} ${styles.cardFront} `}
        variants={cardVariants}
        initial="exit"
        exit="exit"
        animate={shouldEnterCard ? 'enter' : 'exit'}
      >
        <img
          src={cardPreviewUrl}
          alt="sendoutcards"
          className={styles.img}
          onLoad={() => {
            setIsCardPreviewLoaded(true)
          }}
        />
      </motion.div>
      {bottomBadge && (
        <motion.div
          className={`${styles.inners} ${styles.cardFront} `}
          variants={bottomBadge.variants ?? cardVariants}
          initial="exit"
          exit="exit"
          animate={shouldEnterCard ? 'enter' : 'exit'}
        >
          <div className={`${styles.bottomBadge}`}>{bottomBadge.node}</div>
        </motion.div>
      )}
      {topBadge && (
        <motion.div
          className={`${styles.inners} ${styles.cardFront} `}
          variants={topBadge.variants ?? cardVariants}
          initial="exit"
          exit="exit"
          animate={shouldEnterCard ? 'enter' : 'exit'}
        >
          <div className={`${styles.topBadge}`}>{topBadge.node}</div>
        </motion.div>
      )}
    </div>
  )
}

export default ActCardGraphic
